export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/pages/profile/Profile.vue'),
    meta: {
      pageTitle: 'titles.profile',
    },
  },
]
