import { unref } from '@vue/composition-api'
import instance from './instances/base'

export const list = () => instance.get('/events')

export const detail = ({ eventId }) => instance.get(`/events/${unref(eventId)}`)

export const update = ({ eventId, event }) =>
  instance.patch(`/events/${unref(eventId)}`, unref(event))

export const copy = ({ eventId }) =>
  instance.post(`/events/${unref(eventId)}/copy`, {
    id: unref(eventId),
  })

export const create = ({ event }) => instance.post('/events', unref(event))

export const remove = ({ eventId }) =>
  instance.delete(`/events/${unref(eventId)}`)
