<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import { useWindowSize, useCssVar } from '@vueuse/core'
import { useUsersQuery } from '@/store/users'
import useAppConfig from '@core/app-config/useAppConfig'
import store from '@/store'
import router from '@/router'
import { useTypesQuery } from '@/store/types'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    LayoutVertical,
    LayoutFull,
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return 'layout-vertical'
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark',
    ]

    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      )
    }

    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },

  setup() {
    useUsersQuery.detail({
      options: {
        enabled: !!$cookies.get('JWToken'),
        onSuccess(data) {
          if (data.success) {
            store.dispatch('usersStore/set', data.user)
          } else {
            if (data.code === 401 || data.code || 403) {
              $cookies.remove('JWToken')
              store.dispatch('usersStore/set', {
                user: {},
                list: [],
              })
              localStorage.removeItem('userData')
              router.push({ name: 'sign_in' })
            }
          }
        },
      },
    })
    useUsersQuery.list({
      options: {
        enabled: !!$cookies.get('JWToken'),
        onSuccess(data) {
          if (data.success) {
            store.dispatch('usersStore/setList', data.users)
          }
        },
      },
    })

    useTypesQuery.getIdsList({
      options: {
        enabled: !!$cookies.get('JWToken'),
        onSuccess(data) {
          if (data.success) {
            store.dispatch('typesStore/setTypeMaps', data.types)
          }
        },
      },
    })

    provideToast({
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      icon: false,
      timeout: 2000,
      transition: 'Vue-Toastification__fade',
    })

    const { skin, skinClasses } = useAppConfig()
    if (skin.value === 'dark') document.body.classList.add('dark-layout')
    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
}
</script>
