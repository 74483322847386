import { unref } from '@vue/composition-api'
import instance from './instances/base'

export const all = () => instance.get('/roles/all')

export const update = ({ roleId, role }) =>
  instance.patch(`/roles/${unref(roleId)}`, unref(role))

export const create = ({ role }) => instance.post('/roles', unref(role))

export const remove = ({ roleId }) => instance.delete(`/roles/${unref(roleId)}`)
