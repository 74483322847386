export default [
  {
    path: '/types',
    name: 'types',
    component: () => import('@/views/pages/types/Types.vue'),
    meta: {
      pageTitle: 'titles.documentTypes',
    },
  },
  {
    path: '/types/create',
    name: 'type_create',
    component: () => import('@/views/pages/types/TypeCreate.vue'),
    meta: {
      pageTitle: 'titles.documentTypesCreate',
      breadcrumb: [
        {
          text: 'titles.documentTypes',
          to: 'types',
        },
      ],
    },
  },
  {
    path: '/types/:typeId/edit',
    name: 'type_edit',
    props: true,
    component: () => import('@/views/pages/types/TypeEdit.vue'),
    meta: {
      pageTitle: 'titles.documentTypesEdit',
      breadcrumb: [
        {
          text: 'titles.documentTypes',
          to: 'types',
        },
      ],
    },
  },
]
