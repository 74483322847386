export default [
  {
    path: '/documents/',
    name: 'documents',
    props: true,
    component: () => import('@/views/pages/documents/DocumentsResolver.vue'),
    meta: {
      pageTitle: 'titles.documents',
    },
  },
  {
    path: '/documents/:type/',
    name: 'documentsWithType',
    props: true,
    component: () => import('@/views/pages/documents/DocumentsResolver.vue'),
    meta: {
      pageTitle: 'titles.documents',
    },
  },
  {
    path: '/document/:documentId/edit',
    name: 'document_edit',
    props: true,
    component: () => import('@/views/pages/documents/DocumentEdit.vue'),
    meta: {
      pageTitle: 'titles.documentEdit',
      breadcrumb: [
        {
          text: 'titles.documents',
          to: 'documents',
        },
      ],
    },
  },
  {
    path: '/document/create/',
    name: 'document_create',
    component: () => import('@/views/pages/documents/DocumentCreate.vue'),
    meta: {
      pageTitle: 'titles.documentCreate',
      breadcrumb: [
        {
          text: 'titles.documents',
          to: 'documents',
        },
      ],
    },
  },
]
