import { useQuery, useMutation } from 'vue-query'
import { create, update, remove, all } from '@/api/roles'

export const useRolesQuery = {
  all({ options = {} }) {
    return useQuery(['roles'], () => all(), options)
  },

  update({ roleId, role, options = {} }) {
    return useMutation(
      ['role_update', { roleId }],
      () => update({ roleId, role }),
      options
    )
  },

  create({ role, options = {} }) {
    return useMutation(['role_create'], () => create({ role }), options)
  },

  remove({ roleId, options = {} }) {
    return useMutation(
      ['role_remove', { roleId }],
      () => remove({ roleId }),
      options
    )
  },
}

export const rolesStore = {
  namespaced: true,
  state: {
    list: [],
  },
  getters: {
    list: state => state.list,
  },
  mutations: {},
  actions: {},
}
