import { unref } from '@vue/composition-api'
import { instance, instanceUpload } from './instances/attachments'
import { downloadFile } from '@/utils/helpers'

export const upload = ({ fileName, fileBase64, fileType }) =>
  instanceUpload.post('/upload', {
    fileName: unref(fileName),
    fileBase64: unref(fileBase64),
    fileType: unref(fileType),
  })

export const getImage = async ({ filename }) =>
  await instance
    .get('/images/', { params: { filename: unref(filename) } })
    .then(res => {
      const blob = new Blob([res.data], { type: 'image/png' })
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result)
        }
      })
    })

export const download = ({ filename, type }) => {
  const typeMap = {
    file: '/files',
    document: '/documents',
    image: '/images',
  }

  instance
    .get(typeMap[type], { params: { filename: unref(filename) } })
    .then(res => {
      downloadFile(filename, [res.data])
    })
}
