import { useQuery, useMutation } from 'vue-query'
import {
  detail,
  update,
  create,
  list,
  remove,
  copy,
  all,
  checkType,
  getIdsList,
  typeByType,
} from '@/api/types'

export const useTypesQuery = {
  all({ options = {} }) {
    return useQuery(['types'], () => all(), options)
  },

  list({
    filters = {},
    search = '',
    sorting = { sortBy: 'createdAt', sortDesc: false },
    limit = 0,
    offset = 0,
    excludes,
    options = {},
  }) {
    return useQuery(
      ['types', { limit, offset, sorting, filters, search, excludes }],
      () => list({ filters, search, excludes, limit, offset, sorting }),
      options
    )
  },

  detail({ typeId, options = {} }) {
    return useQuery(
      ['type_detail', { typeId }],
      () => detail({ typeId }),
      options
    )
  },

  getDetails({ typeId, options = {} }) {
    return useMutation(
      ['type_detail', { typeId }],
      () => detail({ typeId }),
      options
    )
  },

  update({ typeId, type, options = {} }) {
    return useMutation(
      ['type_update', { typeId }],
      () => update({ typeId, type }),
      options
    )
  },

  copy({ typeId, options = {} }) {
    return useMutation(
      ['type_copy', { typeId }],
      () => copy({ typeId }),
      options
    )
  },

  create({ type, options = {} }) {
    return useMutation(['type_create'], () => create({ type }), options)
  },

  remove({ typeId, options = {} }) {
    return useMutation(
      ['type_remove', { typeId }],
      () => remove({ typeId }),
      options
    )
  },

  check({ typeId, options = {} }) {
    return useMutation(
      ['type_check', { typeId }],
      () => checkType({ typeId }),
      options
    )
  },

  typeByType({ typeType, options = {} }) {
    return useQuery(
      ['type_by_type', typeType],
      () => typeByType({ typeType }),
      options
    )
  },

  getIdsList({ options = {} }) {
    return useQuery(['type_ids'], () => getIdsList(), options)
  },
}

export const typesStore = {
  namespaced: true,
  state: {
    type: {},
    list: [],
    settings: {
      page: 1,
      filters: {},
      sorting: { sortBy: 'createdAt', sortDesc: false },
      limit: 10,
      offset: 0,
      visible: [],
      search: '',
      fields: [],
    },
    baseMapType: {},
    mapId: {},
  },
  getters: {
    settings: state => state.settings,
    baseMapType: state => state.baseMapType,
    mapId: state => state.mapId,
  },
  mutations: {
    SET_SETTINGS(state, settings) {
      state.settings = { ...state.settings, ...settings }
    },
    SET_BASE_MAP_TYPE(state, map) {
      state.baseMapType = map
    },
    SET_MAP_IDS(state, map) {
      state.mapId = map
    },
  },
  actions: {
    setSettings({ commit, state }, settings) {
      commit('SET_SETTINGS', settings)
    },
    setTypeMaps({ commit, state }, types) {
      const base = Object.fromEntries(
        types
          ?.filter(({ type }) => !!type && type !== 'other')
          .map(({ type, _id, name }) => [type, { id: _id, name }]) ?? []
      )

      const all = Object.fromEntries(
        types.map(({ type, _id, name }) => [_id, { type, name }]) ?? []
      )

      commit('SET_MAP_IDS', all)
      commit('SET_BASE_MAP_TYPE', base)
    },
  },
}
