import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import { usersStore } from './users'
import { attachmentsStore } from './attachments'
import { templatesStore } from './templates'
import { settingsStore } from './settings'
import { typesStore } from './types'
import { customersStore } from './customers'
import { documentsStore } from './documents'
import { useJournalQuery } from './journal'
import { useRolesQuery } from './roles'
import { useAnalyseQuery } from './analyse'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    usersStore,
    attachmentsStore,
    templatesStore,
    settingsStore,
    typesStore,
    customersStore,
    documentsStore,
    useJournalQuery,
    useRolesQuery,
    useAnalyseQuery,
  },
  strict: process.env.DEV,
  plugins: [
    createPersistedState({
      key: 'geomanager',
    }),
  ],
})
