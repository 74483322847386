export default [
  {
    path: '/customers/',
    name: 'customers',
    props: true,
    component: () => import('@/views/pages/customers/Customers.vue'),
    meta: {
      pageTitle: 'titles.customers',
    },
  },
  {
    path: '/customers/:customerId/edit',
    name: 'customer_edit',
    props: true,
    component: () => import('@/views/pages/customers/CustomerEdit.vue'),
    meta: {
      pageTitle: 'titles.customerEdit',
      breadcrumb: [
        {
          text: 'titles.customers',
          to: 'customers',
        },
      ],
    },
  },
  {
    path: '/customers/create',
    name: 'customer_create',
    component: () => import('@/views/pages/customers/CustomerCreate.vue'),
    meta: {
      pageTitle: 'titles.customerCreate',
      breadcrumb: [
        {
          text: 'titles.customers',
          to: 'customers',
        },
      ],
    },
  },
]
