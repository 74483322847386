import { unref } from '@vue/composition-api'
import instance from './instances/base'

export const all = () => instance.get('/documents/all')

export const list = ({ filters, search, limit, offset, sorting }) =>
  instance.get('/documents', {
    params: {
      filters: JSON.stringify(unref(filters)),
      search: unref(search),
      limit: unref(limit),
      offset: unref(offset),
      sorting: unref(sorting),
    },
  })

export const detail = ({ documentId }) =>
  instance.get(`/documents/${unref(documentId)}`)

export const dependents = ({ documentId }) =>
  instance.get(`/documents/${unref(documentId)}/dependents`)

export const update = ({ documentId, document }) =>
  instance.patch(`/documents/${unref(documentId)}`, unref(document))

export const copy = ({ documentId }) =>
  instance.post(`/documents/${unref(documentId)}/copy`, {
    id: unref(documentId),
  })

export const create = ({ document }) =>
  instance.post('/documents', unref(document))

export const remove = ({ documentId }) =>
  instance.delete(`/documents/${unref(documentId)}`)

export const checkDocument = ({ documentId }) =>
  instance.get(`/documents/check/document/`, {
    params: { documentId: unref(documentId) },
  })
