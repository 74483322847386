import { unref } from '@vue/composition-api'
import instance from './instances/base'

export const all = () => instance.get('/customers/all')

export const list = ({ filters, search, limit, offset, sorting }) =>
  instance.get('/customers', {
    params: {
      filters: JSON.stringify(unref(filters)),
      search: unref(search),
      limit: unref(limit),
      offset: unref(offset),
      sorting: unref(sorting),
    },
  })

export const detail = ({ customerId }) =>
  instance.get(`/customers/${unref(customerId)}`)

export const update = ({ customerId, customer }) =>
  instance.patch(`/customers/${unref(customerId)}`, unref(customer))

export const copy = ({ customerId }) =>
  instance.post(`/customers/${unref(customerId)}/copy`, {
    id: unref(customerId),
  })

export const create = ({ customer }) =>
  instance.post('/customers', unref(customer))

export const remove = ({ customerId }) =>
  instance.delete(`/customers/${unref(customerId)}`)

export const checkCustomer = ({ customerId }) =>
  instance.get(`/documents/check/customer/`, {
    params: { customerId: unref(customerId) },
  })
