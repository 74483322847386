export default [
  {
    path: '/templates/',
    name: 'templates',
    props: true,
    component: () => import('@/views/pages/templates/Templates.vue'),
    meta: {
      pageTitle: 'titles.templates',
    },
  },
  {
    path: '/templates/:templateId/edit',
    name: 'template_edit',
    props: true,
    component: () => import('@/views/pages/templates/TemplateEdit.vue'),
    meta: {
      pageTitle: 'titles.templateEdit',
      breadcrumb: [
        {
          text: 'titles.templates',
          to: 'templates',
        },
      ],
    },
  },
  {
    path: '/templates/create',
    name: 'template_create',
    component: () => import('@/views/pages/templates/TemplateCreate.vue'),
    meta: {
      pageTitle: 'titles.templateCreate',
      breadcrumb: [
        {
          text: 'titles.templates',
          to: 'templates',
        },
      ],
    },
  },
]
