import { unref } from '@vue/composition-api'
import instance from './instances/base'

export const all = () => instance.get('/types/all')

export const list = ({ filters, search, limit, offset, sorting, excludes }) =>
  instance.get('/types', {
    params: {
      filters: JSON.stringify(unref(filters)),
      search: unref(search),
      limit: unref(limit),
      offset: unref(offset),
      sorting: unref(sorting),
      excludes: unref(excludes),
    },
  })

export const detail = ({ typeId }) => instance.get(`/types/${unref(typeId)}`)

export const update = ({ typeId, type }) =>
  instance.patch(`/types/${unref(typeId)}`, unref(type))

export const copy = ({ typeId }) =>
  instance.post(`/types/${unref(typeId)}/copy`, {
    id: unref(typeId),
  })

export const create = ({ type }) => instance.post('/types', unref(type))

export const remove = ({ typeId }) => instance.delete(`/types/${unref(typeId)}`)

export const checkType = ({ typeId }) =>
  instance.get(`/documents/check/type/`, {
    params: { typeId: unref(typeId) },
  })

export const getIdsList = () => instance.get(`/types/get/ids`)

export const typeByType = ({ typeType }) =>
  instance.get(`/types/type/${unref(typeType)}/`)
