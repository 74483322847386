export default [
  {
    path: '/journal/',
    name: 'journal',
    props: true,
    component: () => import('@/views/pages/journal/Calendar.vue'),
    meta: {
      pageTitle: 'titles.journal',
    },
  },
]
