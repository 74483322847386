<template>
  <div class="d-flex flex-wrap justify-content-center gap-1-5">
    <b-spinner
      v-for="variant in ['primary', 'info', 'danger']"
      :key="variant"
      :variant="variant"
      type="grow"
    />
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  name: 'Loader',
  components: { BSpinner },
}
</script>
