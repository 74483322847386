import { getDocument } from '@/api/attachments'

export const getRandomId = () =>
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .slice(0, 5)

export const debounce = (cb, ms = 500) => {
  let timer
  return (...arg) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      cb.apply({}, arg)
    }, ms)
  }
}

export const getTypeValue = (key, document) =>
  document?.typeFields?.find(field => field.label === key)?.value || ''

export const getAdditionalValue = (key, document) =>
  document?.additional?.[key] ?? ''

export const getMasterTypeValue = (key, document) =>
  document?.masterFields?.find(field => field.name === key.split('_')[1])
    ?.value || ''

export const removeProperty = (propKey, { [propKey]: propValue, ...rest }) =>
  rest

export const downloadFile = (filename, data) => {
  const link = document.createElement('a')
  const url = window.URL || window.webkitURL
  const blob = new Blob(data)
  link.href = url.createObjectURL(blob)
  link.download = filename
  link.click()
}

export const printHandler = html => {
  const w = 1024
  const h = 768
  const left = window.innerWidth / 2 - w / 2
  const top = window.innerHeight / 2 - h / 2
  const printWindow = window.open(
    '',
    'PRINT',
    `height=${h},width=${w},left=${left},top=${top},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`
  )

  const viewHtml = document.querySelector('.ck-editor__main')
  const editorHtml = document.createElement('div')
  editorHtml.innerHTML = html
  const fields = editorHtml.querySelectorAll('[editor-container-id]')

  for (const field of fields) {
    const parent = field.parentElement
    const id = field.getAttribute('editor-container-id')
    const type = field.getAttribute('editor-container-type')
    field.innerHTML = viewHtml.querySelector(`[editor-key=${id}]`).outerHTML

    // TODO: Костыль для обновления стайлов в списке поля
    if (type === 'list' && parent.tagName === 'SPAN') {
      const liElems = field.querySelectorAll('li')
      for (const li of liElems) {
        li.style['fontSize'] = parent.style['fontSize']
        li.style['fontFamily'] = parent.style['fontFamily']
        li.style['color'] = parent.style['color']
        li.style['lineHeight'] = parent.parentElement.style['lineHeight']
      }
    }
  }

  const data = editorHtml.innerHTML

  const style = `
      <style>
        body { width: 210mm; padding: 0.4cm; margin: 0; font-size: 14px; font-family: "Times New Roman", Arial, serif; font-weight: 400;}
        p,ul { margin: 0; line-height: 1.5rem; word-wrap: break-word;}
        span,ol,li { margin: 0; line-height: inherit; word-wrap: break-word;}
        h1 {font-size: 28px;}
        h2 {font-size: 24px;}
        h3 {font-size: 21px;}
        h4 {font-size: 18px;}
        img {max-width: 100%;}
        table {border-collapse: collapse; border-spacing: 0; width: 100%;}
        .table {display: table; margin: 0.9em auto;}
        .table table {height: 100%;}
        .table table td, .table table th {padding: 0.4em; position: relative; vertical-align: middle;}
       .customer-identification { display: none; }
       .image_resized {display: inline-block;}
      </style>
    `
  const printHtml = `
      <html lang="ru-RU">
        <head>
          <title>Print</title>
          ${style}
        </head>
        <body>${data}</body>
      </html>
    `

  printWindow.document.write(printHtml)
  printWindow.document.execCommand('print')
  printWindow.close()
}
