import { useQuery, useMutation } from 'vue-query'
import {
  list,
  detail,
  create,
  update,
  remove,
  copy,
  all,
  checkCustomer,
} from '@/api/customers'

export const useCustomersQuery = {
  all({ options = {} }) {
    return useQuery(['customers'], () => all(), options)
  },

  list({
    filters = {},
    search = '',
    sorting = { sortBy: 'createdAt', sortDesc: false },
    limit = 0,
    offset = 0,
    options = {},
  }) {
    return useQuery(
      ['customers', { limit, offset, sorting, filters, search }],
      () => list({ filters, search, limit, offset, sorting }),
      options
    )
  },

  detail({ customerId, options = {} }) {
    return useQuery(
      ['customer_detail', { customerId }],
      () => detail({ customerId }),
      options
    )
  },

  copy({ customerId, options = {} }) {
    return useMutation(
      ['customer_copy', { customerId }],
      () => copy({ customerId }),
      options
    )
  },

  update({ customerId, customer, options = {} }) {
    return useMutation(
      ['customer_update', { customerId }],
      () => update({ customerId, customer }),
      options
    )
  },

  create({ customer, options = {} }) {
    return useMutation(['customer_create'], () => create({ customer }), options)
  },

  remove({ customerId, options = {} }) {
    return useMutation(
      ['customer_remove', { customerId }],
      () => remove({ customerId }),
      options
    )
  },

  check({ customerId, options = {} }) {
    return useMutation(
      ['customer_check', { customerId }],
      () => checkCustomer({ customerId }),
      options
    )
  },
}

export const customersStore = {
  namespaced: true,
  state: {
    customer: {},
    list: [],
    settings: {
      page: 1,
      filters: {},
      sorting: { sortBy: 'createdAt', sortDesc: false },
      limit: 10,
      offset: 0,
      visible: [],
      search: '',
      fields: [],
    },
  },
  getters: {
    settings: state => state.settings,
  },
  mutations: {
    SET_SETTINGS(state, settings) {
      state.settings = { ...state.settings, ...settings }
    },
  },
  actions: {
    setSettings({ commit, state }, settings) {
      commit('SET_SETTINGS', settings)
    },
  },
}
