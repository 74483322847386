export const domain = ({ subdomain = '', path = '' } = {}) => {
  const apiUrl =
    process.env.NODE_ENV === 'development'
      ? process.env.VUE_APP_DEV_BASE_API
      : process.env.VUE_APP_PROD_BASE_API
  const protocol =
    process.env.NODE_ENV === 'development'
      ? process.env.VUE_APP_DEV_BASE_PROTOCOL
      : process.env.VUE_APP_PROD_BASE_PROTOCOL
  return `${protocol}://${apiUrl}/${subdomain ? subdomain + '/' : ''}${path}`
}

export const useCursorJSONResponse = r => {
  const {
    data = {
      success: false,
      error: null,
    },
  } = r || {}
  return (data || {}).data || {}
}

export const useJSONResponse = r => {
  const {
    data = {
      success: false,
      error: null,
    },
  } = r || {}
  return (data || {}).data || {}
}

export const useRequestError = async r => {
  const {
    data = {
      success: false,
      error: null,
      code: 400,
    },
  } = r || {}
  console.log('useRequestError', data)
  return (data || {}).data || {}
}
