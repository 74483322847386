import { useQuery } from 'vue-query'
import { counts } from '@/api/analyse'

export const useAnalyseQuery = {
  counts({ type, options = {} }) {
    return useQuery(
      ['analyse_counts', { type }],
      () => counts({ type }),
      options
    )
  },
}
