export default [
  {
    path: '/sign_in',
    name: 'sign_in',
    component: () => import('@/views/pages/auth/SignIn.vue'),
    meta: {
      pageTitle: 'Sign In',
      useWithoutLogin: true,
      layout: 'full',
    },
  },
  {
    path: '/sign_up',
    name: 'sign_up',
    props: route => ({ invite: route.query.invite }),
    component: () => import('@/views/pages/auth/SignUp.vue'),
    meta: {
      pageTitle: 'Sign Up',
      useWithoutLogin: true,
      layout: 'full',
    },
  },
]
