import axios from 'axios'
import {
  domain,
  useJSONResponse,
  useRequestError,
} from '@/api/instances/common'

const instance = axios.create({
  baseURL: domain(),
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
})

instance.interceptors.request.use(({ headers, params, ...restConfig } = {}) => {
  if (headers) {
    headers.Authorization = $cookies.get('JWToken')
  }
  return { ...restConfig, headers, params }
})

instance.interceptors.response.use(useJSONResponse, useRequestError)

export default instance
