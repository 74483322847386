import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import VueCookies from 'vue-cookies'
import Ripple from 'vue-ripple-directive'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import { VueQueryPlugin } from 'vue-query'
import VueInputMask from '@/libs/vue-input-mask/dist'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import './global-components'
import '@/libs/portal-vue'
import '@/libs/toastification'
require('@core/scss/core.scss')
require('@/assets/scss/style.scss')

import CKEditor from '@ckeditor/ckeditor5-vue2'

Vue.use(CKEditor)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(require('vue-moment'))

// Directives
Ripple.color = 'rgba(255, 255, 255, 0.35)'
Vue.directive('ripple', Ripple)
Vue.directive('mask', VueInputMask)
// Composition API
Vue.use(VueCompositionAPI)

// Vue Query Plugin
Vue.use(VueQueryPlugin, {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        //staleTime: 3000,
      },
    },
  },
})

// Vue Cookies
Vue.use(VueCookies)

Vue.config.productionTip = false

const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

window.app = app
