import axios from 'axios'
import { domain, useJSONResponse } from '@/api/instances/common'

const instance = axios.create({
  baseURL: domain({ subdomain: 'attachments' }),
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
  responseType: 'blob',
})

instance.interceptors.request.use(({ headers, params, ...restConfig } = {}) => {
  if (headers) {
    headers.Authorization = $cookies.get('JWToken')
  }

  return { ...restConfig, headers, params }
})

const instanceUpload = axios.create({
  baseURL: domain({ subdomain: 'attachments' }),
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
})

instanceUpload.interceptors.response.use(useJSONResponse)

instanceUpload.interceptors.request.use(
  ({ headers, params, ...restConfig } = {}) => {
    if (headers) {
      headers.Authorization = $cookies.get('JWToken')
    }

    return { ...restConfig, headers, params }
  }
)

export { instance, instanceUpload }
