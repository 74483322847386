import { useQuery, useMutation } from 'vue-query'
import { detail, update, list } from '@/api/users'

export const useUsersQuery = {
  list({ userId, options = {} }) {
    return useQuery(['user_list', { userId }], () => list(), options)
  },

  detail({ userId, options = {} }) {
    return useQuery(['user_detail', { userId }], () => detail(), options)
  },

  update({ user, options = {} }) {
    return useMutation(
      ['update_user', { user }],
      () => update({ user }),
      options
    )
  },
}

export const usersStore = {
  namespaced: true,
  state: {
    user: {},
    list: [],
  },
  getters: {
    user: state => state.user,
    list: state => state.list,
    isLogged: state => !!Object.keys(state.user).length,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_LIST_USER(state, list) {
      state.list = list
    },
  },
  actions: {
    set({ commit, state }, user) {
      commit('SET_USER', user)
    },
    setList({ commit, state }, list) {
      commit('SET_LIST_USER', list)
    },
  },
}
