import { useQuery, useMutation } from 'vue-query'
import {
  list,
  detail,
  create,
  update,
  remove,
  copy,
  all,
  checkTemplate,
} from '@/api/templates'

export const useTemplatesQuery = {
  all({ options = {} }) {
    return useQuery(['templates'], () => all(), options)
  },

  list({
    filters = {},
    search = '',
    sorting = { sortBy: 'createdAt', sortDesc: false },
    limit = 0,
    offset = 0,
    options = {},
  }) {
    return useQuery(
      ['templates', { limit, offset, sorting, filters, search }],
      () => list({ filters, search, limit, offset, sorting }),
      options
    )
  },

  detail({ templateId, options = {} }) {
    return useQuery(
      ['template_detail', templateId],
      () => detail({ templateId }),
      options
    )
  },

  getDetails({ templateId, options = {} }) {
    return useMutation(
      ['template_detail', templateId],
      () => detail({ templateId }),
      options
    )
  },

  update({ templateId, template, options = {} }) {
    return useMutation(
      ['template_update', { templateId }],
      () => update({ templateId, template }),
      options
    )
  },

  copy({ templateId, options = {} }) {
    return useMutation(
      ['template_copy', { templateId }],
      () => copy({ templateId }),
      options
    )
  },

  create({ template, options = {} }) {
    return useMutation(['template_create'], () => create({ template }), options)
  },

  remove({ templateId, options = {} }) {
    return useMutation(
      ['template_remove', { templateId }],
      () => remove({ templateId }),
      options
    )
  },

  check({ templateId, options = {} }) {
    return useMutation(
      ['template_check', { templateId }],
      () => checkTemplate({ templateId }),
      options
    )
  },
}

export const templatesStore = {
  namespaced: true,
  state: {
    template: {},
    list: [],
    settings: {
      page: 1,
      filters: {},
      sorting: { sortBy: 'createdAt', sortDesc: false },
      limit: 10,
      offset: 0,
      visible: [],
      search: '',
      fields: [],
    },
  },
  getters: {
    settings: state => state.settings,
  },
  mutations: {
    SET_SETTINGS(state, settings) {
      state.settings = { ...state.settings, ...settings }
    },
  },
  actions: {
    setSettings({ commit, state }, settings) {
      commit('SET_SETTINGS', settings)
    },
  },
}
