import { useQuery, useMutation } from 'vue-query'
import {
  list,
  detail,
  create,
  update,
  remove,
  copy,
  all,
  checkDocument,
  dependents,
} from '@/api/documents'

export const useDocumentsQuery = {
  all({ options = {} }) {
    return useQuery(['documents'], () => all(), options)
  },

  list({
    filters = {},
    search = '',
    sorting = { sortBy: 'createdAt', sortDesc: false },
    limit = 0,
    offset = 0,
    options = {},
  }) {
    return useQuery(
      ['documents', { limit, offset, sorting, filters, search }],
      () => list({ filters, search, limit, offset, sorting }),
      options
    )
  },

  detail({ documentId, options = {} }) {
    return useQuery(
      ['document_detail', { documentId }],
      () => detail({ documentId }),
      options
    )
  },

  dependents({ documentId, options = {} }) {
    return useQuery(
      ['document_dependents', { documentId }],
      () => dependents({ documentId }),
      options
    )
  },

  update({ documentId, document, options = {} }) {
    return useMutation(
      ['document_update', { documentId }],
      () => update({ documentId, document }),
      options
    )
  },

  copy({ documentId, options = {} }) {
    return useMutation(
      ['document_copy', { documentId }],
      () => copy({ documentId }),
      options
    )
  },

  create({ document, options = {} }) {
    return useMutation(['document_create'], () => create({ document }), options)
  },

  remove({ documentId, options = {} }) {
    return useMutation(
      ['document_remove', { documentId }],
      () => remove({ documentId }),
      options
    )
  },

  check({ documentId, options = {} }) {
    return useMutation(
      ['document_check', { documentId }],
      () => checkDocument({ documentId }),
      options
    )
  },
}

export const documentsStore = {
  namespaced: true,
  state: {
    document: {},
    list: [],
    settings: {
      other: {
        page: 1,
        filters: {},
        sorting: { sortBy: 'createdAt', sortDesc: false },
        limit: 10,
        offset: 0,
        visible: [],
        search: '',
        fields: [],
      },
      agreement: {
        page: 1,
        filters: {},
        sorting: { sortBy: 'createdAt', sortDesc: false },
        limit: 10,
        offset: 0,
        visible: [],
        search: '',
        fields: [],
      },
      check: {
        page: 1,
        filters: {},
        sorting: { sortBy: 'createdAt', sortDesc: false },
        limit: 10,
        offset: 0,
        visible: [],
        search: '',
        fields: [],
      },
      receipt: {
        page: 1,
        filters: {},
        sorting: { sortBy: 'createdAt', sortDesc: false },
        limit: 10,
        offset: 0,
        visible: [],
        search: '',
        fields: [],
      },
    },
  },
  getters: {
    otherSettings: state => state.settings.other,
    agreementSettings: state => state.settings.agreement,
    checkSettings: state => state.settings.check,
    receiptSettings: state => state.settings.receipt,
  },
  mutations: {
    SET_SETTINGS(state, { settings, type }) {
      state.settings[type] = { ...state.settings[type], ...settings }
    },
  },
  actions: {
    setSettings({ commit, state }, { settings, type }) {
      commit('SET_SETTINGS', { settings, type })
    },
  },
}
