import { unref } from '@vue/composition-api'
import instance from './instances/base'

export const all = () => instance.get('/templates/all')

export const list = ({ filters, search, limit, offset, sorting }) =>
  instance.get('/templates', {
    params: {
      filters: JSON.stringify(unref(filters)),
      search: unref(search),
      limit: unref(limit),
      offset: unref(offset),
      sorting: unref(sorting),
    },
  })

export const detail = ({ templateId }) =>
  instance.get(`/templates/${unref(templateId)}`)

export const update = ({ templateId, template }) =>
  instance.patch(`/templates/${unref(templateId)}`, unref(template))

export const copy = ({ templateId }) =>
  instance.post(`/templates/${unref(templateId)}/copy`, {
    id: unref(templateId),
  })

export const create = ({ template }) =>
  instance.post('/templates', unref(template))

export const remove = ({ templateId }) =>
  instance.delete(`/templates/${unref(templateId)}`)

export const checkTemplate = ({ templateId }) =>
  instance.get(`/documents/check/template/`, {
    params: { templateId: unref(templateId) },
  })
