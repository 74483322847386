import { useMutation } from 'vue-query'
import { upload } from '@/api/attachments'

export const useAttachmentsQuery = {
  upload({ fileName, fileBase64, fileType, options = {} }) {
    return useMutation(
      ['upload_attachment', { fileName }],
      () => upload({ fileName, fileBase64, fileType }),
      options
    )
  },
}

export const attachmentsStore = {
  namespaced: true,
  state: {
    attach: {},
  },
  getters: {},
}
