import { useQuery, useMutation } from 'vue-query'
import { detail, update } from '@/api/settings'

export const useSettingsQuery = {
  detail({ options = {} }) {
    return useQuery(['settings_detail'], () => detail(), options)
  },

  update({ settings, options = {} }) {
    return useMutation(
      ['update_settings', { settings }],
      () => update({ settings }),
      options
    )
  },
}

export const settingsStore = {
  namespaced: true,
  state: {
    settings: {},
  },
  getters: {
    settings: state => state.settings,
  },
  mutations: {
    SET_SETTINGS(state, settings) {
      state.settings = settings
    },
  },
  actions: {
    set({ commit, state }, settings) {
      commit('SET_SETTINGS', settings)
    },
  },
}
