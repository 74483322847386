import Vue from 'vue'
import VueRouter from 'vue-router'

import auth from './auth'
import user from './user'
import templates from './templates'
import documents from './documents'
import types from './types'
import customers from './customers'
import journal from './journal'
import roles from './roles'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...auth,
    ...user,
    ...templates,
    ...documents,
    ...types,
    ...customers,
    ...journal,
    ...roles,
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/pages/home/Home.vue'),
      meta: {
        pageTitle: 'titles.home',
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/pages/settings/Settings.vue'),
      meta: {
        pageTitle: 'titles.settings',
        breadcrumb: [
          {
            text: 'titles.settings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/pages/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

const publicPages = ['sign_in', 'sign_up']

router.beforeEach((to, _, next) => {
  const isAuthenticated = !!$cookies.get('JWToken')
  if (!isAuthenticated && !publicPages.includes(to.name)) {
    next({ name: 'sign_in' })
  } else {
    next()
  }
})

router.afterEach(to => {
  if (!!to?.params?.type && to.name === 'documentsWithType') {
    to.meta.pageTitle = `titles.${to.params.type}s`
  }
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
export default router
