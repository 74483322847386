import { useQuery, useMutation } from 'vue-query'
import { list, detail, create, update, remove, copy } from '@/api/events'

export const useJournalQuery = {
  all({ options = {} }) {
    return useQuery(['events'], () => list(), options)
  },

  detail({ eventId, options = {} }) {
    return useQuery(
      ['event_detail', { eventId }],
      () => detail({ eventId }),
      options
    )
  },

  copy({ eventId, options = {} }) {
    return useMutation(
      ['event_copy', { eventId }],
      () => copy({ eventId }),
      options
    )
  },

  update() {
    return useMutation(update)
  },

  create() {
    return useMutation(create)
  },

  remove() {
    return useMutation(remove)
  },
}

export const eventsStore = {
  namespaced: true,
  state: {
    event: {},
    list: [],
    settings: {},
  },
  getters: {
    settings: state => state.settings,
  },
  mutations: {
    SET_SETTINGS(state, settings) {
      state.settings = { ...state.settings, ...settings }
    },
  },
  actions: {
    setSettings({ commit, state }, settings) {
      commit('SET_SETTINGS', settings)
    },
  },
}
