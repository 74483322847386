export default [
  {
    path: '/roles/',
    name: 'roles',
    props: true,
    component: () => import('@/views/pages/roles/RolesManager.vue'),
    meta: {
      pageTitle: 'titles.roles',
    },
  },
]
